import React from 'react';
import Box from '@webAtoms/Box/Box';
import Container from '@webAtoms/Container/Container';
import BlogDetailed from '@localTypes/BlogDetailed';
import * as Route from '@routes';
import * as styles from './blogPost.module.scss';
import { ucfirst } from 'essential-ts-utils';
import YoutubeEmbed from '@webAtoms/YoutubeEmbed/YoutubeEmbed';
import SvgAttachment from './attachment.inline.svg';
import Button from '@webAtoms/Button/Button';

export interface BlogPostProps {
  blog: BlogDetailed;
}

const BlogPost: React.FC<BlogPostProps> = ({ blog }) => {
  console.log(blog);
  return (
    <Box>
      <Container>
        <Box className={styles.blogPostInner}>
          <Box className={styles.blogPostHeading}>
            <h1>{blog.title}</h1>

            <Box className={styles.blogPostHeadingDetails}>
              <Box className={styles.blogPostHeadingTags}>
                {blog.tags.map(({ key, label }) => (
                  <a key={key} href={`${Route.journal()}#${key}`}>
                    #{ucfirst(label)}
                  </a>
                ))}
              </Box>
              <Box>
                {blog.date.toLocaleDateString('en-GB', {
                  month: 'long',
                  year: 'numeric',
                })}
              </Box>
            </Box>

            {blog.file && (
              <Box className={styles.attachment}>
                <Button
                  id="FileButton"
                  href={blog.file}
                  intent="primary"
                  target="_blank"
                  icon="download"
                >
                  {blog.fileLabel || blog.file.split('/').pop()}
                </Button>
              </Box>
            )}
          </Box>
          <Box className={styles.blogPostBody}>
            {blog.video && (
              <Box className={styles.blogPostVideo}>
                <YoutubeEmbed fluid={false} videoId={blog.video} />
              </Box>
            )}

            {/* <div dangerouslySetInnerHTML={{ __html: blog.html }} /> */}
            <BlogPostBody body={blog.html} />
          </Box>
        </Box>
      </Container>

      {blog.embed && (
        <Container className="embed-container">
          <Box className="embed-content" htmlContent={blog.embed} />
        </Container>
      )}
    </Box>
  );
};

interface BlogPostBodyProps {
  body: string;
}

const BlogPostBody: React.FC<BlogPostBodyProps> = ({ body }) => {
  const postBodyRef = React.useRef<HTMLDivElement>(null);

  const [parsedBody, setParsedBody] = React.useState(body);

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      try {
        const parser = new DOMParser();
        const doc = parser.parseFromString(`<body>${body}</body>`, 'text/html');

        const $ActualFileButton = document.getElementById('FileButton');
        const $FileButton = doc.querySelector('button#File');

        if ($ActualFileButton && $FileButton) {
          $FileButton.outerHTML = $ActualFileButton.outerHTML;
          $ActualFileButton.remove();
        }

        setParsedBody(doc.querySelector('body')?.innerHTML || body);
      } catch (e) {}
    }
  }, [postBodyRef]);

  return (
    <div
      className="blog-post-body"
      dangerouslySetInnerHTML={{ __html: parsedBody }}
      ref={postBodyRef}
    />
  );
};

export default BlogPost;
